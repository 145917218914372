import eventBus from "../events/EventBus";
import Auth from "../store/reducers/Auth";
import { MODAL_CONFIRMED, SHOW_CONFIRM_BOX } from "./const";
import axios from "../Http";

export function getHashValues(hash) {
  return Object.values(hash); // needs modern browser
}
export function hashById(array) {
  let hash = {};
  for (let item of array) {
    hash[item.uid] = item;
  }
  return hash;
}

export function excludeById(array, uid) {
  return array.filter((item) => {
    return item.uid !== uid;
  });
}

export function getTodayStr() {
  return new Date().toISOString().replace(/T.*$/, "");
}
export function getUser() {
  const user = JSON.parse(localStorage.getItem("user"));
  const userInfo = user.data;
  if (!userInfo) {
    Auth(
      { isAuthenticated: false, user: null },
      {
        type: "AUTH_LOGOUT",
      }
    );
    window.location.href = "/login";
  }
  return userInfo.user;
}
export function s3Asset(media) {
  return process.env.REACT_APP_CDN_URL + "/" + media;
}

export const ratingColors = [
  "error",
  "warning",
  "yellowMain",
  "green4",
  "mintMain",
];

export const getRatingColor = (rating) => {
  return ratingColors[rating - 1] || ratingColors[0];
};

export function downloadViaLink(url) {

  var urlParams = new URL(url);
  var fileName = encodeURIComponent(urlParams.searchParams.get("file_name"));
  const downloadURI = process.env.REACT_APP_CDN_URL + '/' + fileName
  window.open(downloadURI, '_blank')

  // axios({
  //   url: url, //your url
  //   method: "GET",
  //   responseType: "blob", // important
  // }).then((response) => {
  //   // create file link in browser's memory
  //   const href = URL.createObjectURL(response.data);

  //   // create "a" HTML element with href to file & click
  //   const link = document.createElement("a");
  //   link.href = href;
  //   link.setAttribute("download", fileName); //or any other extension
  //   document.body.appendChild(link);
  //   link.click();

  //   // clean up "a" element & remove ObjectURL
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(href);
  // });
}

export function confirm(data) {
  let config = {};

  if (typeof data == "string") config.body = data;
  if (typeof data == "object") config = data;

  eventBus.dispatch(SHOW_CONFIRM_BOX, config);

  return new Promise((resolve, reject) => {
    eventBus.on(MODAL_CONFIRMED, resolve);
  });
}
