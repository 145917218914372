import * as ActionTypes from "../action-types";
const initialState = { data: [] };

const widgetsColor = (state, payload) => {
    const stateObj = { ...state, isAuthenticated: true, data: payload};
    window.widgetsColor = payload;
    return stateObj;  
};

const WidgetsColor = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ActionTypes.WIDGETS_COLOR:
            return widgetsColor(state, payload);
        default:
            return state;
    }
};

export default WidgetsColor;
