import * as ActionTypes from "../action-types";
import { filterMenus } from "../../utills/user-permission";

const initialState = {
  navigations: [],
  permissions: [],
};

const userNavigationsHandler = (state, payload) => {
  const stateObj = {
    ...state,
    navigations: filterMenus(payload),
  };
  return stateObj;
};

const userPermissionsHandler = (state, payload) => {
  const stateObj = {
    ...state,
    permissions: payload,
  };
  return stateObj;
};

const UserNavigations = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.NAVIGATIONS:
      return userNavigationsHandler(state, payload);
    case ActionTypes.USER_PERMISSIONS:
      return userPermissionsHandler(state, payload);
    default:
      return state;
  }
};

export default UserNavigations;
