import * as ActionTypes from "../action-types";

export function authLogin(payload) {
  return {
    type: ActionTypes.AUTH_LOGIN,
    payload,
  };
}

export function authLogout() {
  return {
    type: ActionTypes.AUTH_LOGOUT,
  };
}

export function authCheck() {
  return {
    type: ActionTypes.AUTH_CHECK,
  };
}

export function appData(payload) {
  return {
    type: ActionTypes.ACCOUNT_DATA,
    payload: payload.data,
  };
}

export function widgetsColor(payload) {
  return {
    type: ActionTypes.WIDGETS_COLOR,
    payload: payload,
  };
}

export function userNavigations(payload) {
  return {
    type: ActionTypes.NAVIGATIONS,
    payload: payload,
  };
}

export function userPermissions(payload) {
  return {
    type: ActionTypes.USER_PERMISSIONS,
    payload: payload,
  };
}
