//@ts-nocheck
import React, { useEffect, useState } from "react";
import eventBus from "../../events/EventBus";
import { MODAL_CONFIRMED, SHOW_CONFIRM_BOX } from "../../utills/const";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import Icon from "@mdi/react";
import Button from "@mui/material/Button";
import { mdiDelete } from "@mdi/js";

function ConfirmBox() {
  const [visible, setVisible] = useState(false);
  const [config, setConfig] = useState({});

  useEffect(() => {
    eventBus.on(SHOW_CONFIRM_BOX, handleConfirm);
  }, []);

  function handleConfirm(toastConfig = {}) {
    setConfig({ ...toastConfig });
    setVisible(!visible);
  }

  function closeConfirm(res) {
    setConfig({});
    setVisible(false);
    eventBus.dispatch(MODAL_CONFIRMED, res);
  }

  if (!visible) return null;

  return (
    <Dialog open={visible} onClose={() => closeConfirm(false)}>
      <Box className="smallModalForm">
        {config?.icon && (
          <Button
            variant="contained"
            color="greenMain"
            mb={2}
            onClick={visible}
          >
            <Icon path={config.icon} size={1} />
          </Button>
        )}
        <Typography variant="h4">{config.title}</Typography>
        <Typography variant="p">{config.message}</Typography>
        <Stack direction="row" gap={1} mt={1}>
          <Button
            variant="contained"
            color="gray"
            mb={2}
            onClick={() => closeConfirm(false)}
          >
            {config.cancelText}
          </Button>
          {config?.confirmText && (
            <Button
              variant="contained"
              color="primaryMain"
              mb={2}
              onClick={() => closeConfirm(true)}
            >
              {config.confirmText}
            </Button>
          )}
        </Stack>
      </Box>
    </Dialog>
  );
}

export default ConfirmBox;
