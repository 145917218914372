import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { Provider } from "react-redux";
import * as action from "./store/actions";
import { Suspense } from "react";
import Loading from "./components/loading";
import Toast from "./components/toast";
import { ThemeProvider } from "@mui/material";
import { appTheme } from "./themes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { store } from "./store";
import appData from "./services/appDataService";
import widgetsColor from "./services/widgetsColorService";
import ConfirmBox from "./components/confirm-box";
require("./utills/monkey-patches");

(() =>  {
  store.subscribe(() => {
    const {AppData:{data}} = store.getState()
    if(data.authenticated){
        const pushConfig = {id:`${data.user.uid}`,tags:`${data.team.name},${data.user.full_name},${data.user.uid}`}
        window.progressier.add(pushConfig) // push notification subscription
    }
});
})()

store.dispatch(action.authCheck());
store.dispatch(appData({ action: "account_data" }));
store.dispatch(widgetsColor({ action: "widgets_color" }));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={appTheme}>
      <Provider store={store}>
        <Suspense fallback={<Loading size="center-loader" />}>
          <BrowserRouter>
            <Toast />
            <ConfirmBox />
            <App />
            <Routes />
          </BrowserRouter>
        </Suspense>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
