import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  palette: {
    primaryDark: {
      main: "#0328f6",
      //   contrastText: "#fff",
    },
    primaryMain: {
      main: "#2c4bff",
      contrastText: "#fff",
    },
    primaryLight: {
      main: "#697bff",
    },
    primaryPale: {
      main: "#d9dbff",
      contrastText: "#fff",
    },
    primaryUltrapale: {
      main: "#f3f5ff",
      contrastText: "#2c4bff",
    },
    gray: {
      main: "#eaeaea",
      contrastText: "#404040",
    },

    blackUltrasurface: {
      main: "#F6F6F6",
      contrastText: "#000",
    },

    black100: {
      main: "#151515",
    },
    black60: {
      main: "#404040",
    },
    black40: {
      main: "#7b7d82",
    },
    Orange: {
      main: "#FC8019",
    },
    mintDark: {
      main: "#009E8A",
      contrastText: "#fff",
    },
    mintMain: {
      main: "#00C5AC",
    },
    green4: {
      main: "#9CC005",
    },
    greenMain: {
      main: "#F5F9E6",
      contrastText: "#9CC005",
    },
    yellowMain: {
      main: "#FFAA00",
    },
    orangeMain: {
      main: "#F87C18",
    },
    orangeUltrapale: {
      main: "#FEF2E8",
      contrastText: "#F87C18",
    },

    redMain: {
      main: "#DF3E4D",
      contrastText: "#ffffff",
    },
    white: {
      main: "#ffffff",
    },
    deleteIcon: {
      main: "#FCECED",
      contrastText: "#DF3E4D",
    },

    responseRate1: {
      main: "#3F53EA",
    },
    responseRate2: {
      main: "#586BFE",
    },
    responseRate3: {
      main: "#7A8AFE",
    },
    responseRate4: {
      main: "#99A5FF",
    },

    sentiment1: {
      main: "#33CC99",
    },
    sentiment2: {
      main: "#E14F5C",
    },
    sentiment3: {
      main: "#FFD561",
    },
    sentiment4: {
      main: "#5CC3F1",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "14px",
          height: "52px",
          minWidth: "52px",
          lineHeight: "24px",
          boxShadow: "none",
          textTransform: "capitalize",
          fontWeight: 600,
          borderRadius: "8px",
          gap: "10px",
          fontFamily: 'inherit',
          "&:hover": {
            boxShadow: "none",
          },
          "&.Mui-disabled":{
            opacity:"0.4",
          },
          "&.MuiButton-sizeSmall":{
            height:"32px",
            borderColor:"inherit",
            fontWeight:400,
            display:"flex",
            aliginItems:"center",
            gap:"8px",

          },
          ".MuiButton-startIcon":{
            margin:0,
            padding:0,
          }
        },
        
      },
    },

    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: "700",
          fontSize: "32px",
          lineHeight: "39px",
          color: " #252525",
          fontFamily: "inherit",
        },

        h5: {
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#2C4BFF",
          fontFamily: "inherit",
        },
        h6: {
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#151515",
          fontFamily: "inherit",
        },
        h4: {
          fontWeight: " 600",
          fontSize: "20px",
          lineHeight: "28px",
          color: "#151515",
          fontFamily: "inherit",
        },
        h3: {
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#151515",
          fontFamily: "inherit",
        },

        p: {
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "26px",
          color: "#404040",
          fontFamily: "inherit",
        },
        subtitle1: {
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          color: "#252525",
          fontFamily: "inherit",
        },
        subtitle2: {
          fontSize: "12px",
          fontWeight: 600,
          color: "#404040",
          textAlign: "center",
          fontFamily: "inherit",
          textTransform: "uppercase",
          lineHeight: "16px",
        },
        subtitle3: {
          fontSize: "12px",
          fontWeight: 600,
          color: "#151515",
          fontFamily: "inherit",
          textTransform: "uppercase",
          lineHeight: "16px",
        },
        subtitle4: {
          fontSize: "12px",
          fontWeight: 400,
          color: "#151515",
          fontFamily: "inherit",
          color: "#404040",
          lineHeight: "16px",
        },
        caption: {},
        body1: {
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "26px",
          color: "#151515",
          fontFamily: "inherit",
        },
        body2: {
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#7B7D82",
          fontFamily: "inherit",
        },

        body3: {
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "26px",
          color: "#404040",
          fontFamily: "inherit",
        },
        body4: {
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#FFFFFF",
          fontFamily: "inherit",
        },
        body5: {
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          color: "#404040",
          fontFamily: "inherit",
        },

        body6: {
          fontSize: "12px",
          fontWeight: 600,
          color: "#ffff",
          lineHeight: "16px",
          fontFamily: "inherit",
        },
        body7: {
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "26px",
          color: "#2C4BFF",
          fontFamily: "inherit",
        },
        body8: {
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#151515",
          fontFamily: "inherit",
        },
        body9: {
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#7B7D82",
          fontFamily: "inherit",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          fontFamily: "inherit",
          color: "#252525 ",
          textAlign: "right",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "24px",
          display: "inline-block",
          " &:hover": {},
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          border: "1px solid #EAEAEA",
          borderRadius: "4px",
          background: "#FFFFFF",
          border: "none",
          fontFamily: "inherit",
        },   
      },
    },
    MuiInputBase :{
      styleOverrides: {
        root: { 
          gap:"8px",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          fontFamily: "inherit",
          color:"#151515",
          "&.Mui-error":{
            color:"#B2323E",
            "svg":{
              color:"#B2323E",
            }
          },
          "&.Mui-focused":{
            color:"#151515",
            "svg":{
              color:"#2C4BFF",
            }
          },
          "&.Mui-disabled":{
            color:"#7B7D82",
            backgroundColor:"#F6F6F6",
          }
        }
      }
    },
    
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: "52px",
          // border: "1px solid #EAEAEA",
          borderRadius: "8px",
          background: "#FFFFFF",
          fontFamily: "inherit",
          "&:focused": {
            "&:notchedOutline": {
              border: "2px solid #2C4BFF",
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          // boxShadow: "0px 2px 3px -1px rgba(0, 0, 0, 0.06)",
          borderRadius: "20px",
          boxShadow: "none",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          boxShadow: "none",
          padding: "0 4px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#EAEAEA",
          maxWidth: "768px",
          backgroundColor: "#eaeaea",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: "48px",
          background: "#FFFFFF",
          "& .MuiTabs-indicator": {
            backgroundColor: "#2C4BFF",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: "48px",
          background: "#FFFFFF",
          fontWeight: "600",
          fontSize: "14px",
          // lineHeight: "24px",
          borderBottom: "1px solid #E1E1E2",
          color: "#7B7D82",
          textTransform: "capitalize",
          fontFamily: "inherit",
          "&.Mui-selected": {
            color: "#151515",
          },
          "&.MuiTab-iconWrapper": {
            color: "##2C4BFF",
          },
          // "&.MuiSvgIcon-root": {
          //   color: "##2C4BFF",
          // },
        },
        "&.MuiTab-iconWrapper": {
          marginRiht: "11px",
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "0px",
          backgroundColor: "transparent",
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            margin: "0",
          },
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0px",
          minHeight: "48px",
          margin: "0",
          "&:hover": {
            // minHeight: "48px",
            margin: "0px",
          },
          "&.Mui-expanded": {
            minHeight: "48px",
          },
        },
        MuiAccordionSummaryContent: {
          styleOverrides: {
            margin: "0",
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "24px",
          fontFamily: "inherit",
          borderRadius: "40px",
          gap: "4px",
          borderColor: "#E1E1E2",
          "&:active": {
            boxShadow:'none',
          },
          "&.MuiChip-avatar": {
            borderColor: "#E1E1E2",
            width: "24px",
          },
          "&.MuiChip-label ": {
            paddingLeft: "4px",
            paddingRight: "8px",
          },
          ".MuiChip-labelSmall ": {
            paddingLeft: "6px",
            paddingRight: "6px",
            display:"flex",
            aliginItems:"center",
            justifyContent:"center",
            minWidth:"24px",
            
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: "4px",
          borderRadius: "8px",
          backgroundColor:"#EAEAEA",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: "80%",
          boxShadow:
            "0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)",
          background: "#fff",
          // transition: "transition: 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, ",

          // "&.MuiPaper-root ": {
          //   transition: " all 0.2s linear",
          // },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {},
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#2C4BFF",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: "auto",
          marginRight: "0",
          gap: "4px",
          marginLeft: "0",
          "&.active":{
            backgroundColor:"#F3F5FF",
            ".MuiTypography-root":{
              color:"#2c4bff",
            }
          },
          "&.Mui-disabled":{
            opacity:"0.4",
          }
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          // paddingTop: "0px",
          "&.Mui-checked": {
            color: "#2C4BFF",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiDialog-paper": {
            boxShadow: "none",
            margin: "0px",
            background: "transparent",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "20px",
          color: "#7B7D82",
          margin: "0",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "2px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          width: "50%",
        },
        head: {
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#151515",
          fontFamily: "inherit",
          letterSpacing: "0.17px",
          padding: "16px 16px",
          whiteSpace: "nowrap",
        },
        body: {
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#404040",
          fontFamily: "inherit",
          padding: "14px 16px",
          whiteSpace: "nowrap",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {},
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#151515",
          fontSize: "12px",
          padding: " 8px 12px",
        },
        tooltipPlacementBottom: {
          marginTop: "-10px",
        },
        arrow: {
          color: "#151515",
        },
      },
    },
    MuiList:{
      styleOverrides: {
        root: {
          padding:"0"  
         },
      }
    },
    MuiListItem: {
      styleOverrides: {
      root: {
       padding:"0"  
      },
    }
    },
    MuiListItemButton:{
      styleOverrides:{
       root:{
         padding:"12px 16px",
         gap:"8px",
         borderBottom:"1px solid #E1E1E2",
         "& .MuiCheckbox-root":{
          padding:"2px",
          marginLeft:"0px",
         },
         "&.Mui-focusVisible":{
          backgroundColor:"#F6F6F6",
         },
         "&.active":{
          backgroundColor:"#F3F5FF",
          ".semibold":{
            color:"#2c4bff",
          }
         }
          },
          
        }
       } , 
       MuiListItemText:{
          styleOverrides:{
          root:{
              margin:"0px",
              ".MuiTypography-root":{
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "24px",
                color: "#404040",
                fontFamily: "inherit",
                ".MuiAvatar-root .MuiTypography-root":{
                  fontSize:"12px",
                }
               }
              },
            }
       } ,
       MuiAvatar:{
        styleOverrides:{
          root:{
            backgroundColor:"#eaeaea",
            color:"#404040"
          }}
       },
       MuiMenuItem:{
        styleOverrides:{
          root:{
            fontFamily:"inherit",
            "&:active":{
              background:  "rgba(21, 21, 21, 0.04)"
            }

          }
       } } 

  },
});
