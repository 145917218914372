import React, { lazy } from "react";
import { Routes as Switch, Route } from "react-router-dom";
import AppLayout from "../views/layouts/app";
const SplashScreen = lazy(() => import("../views/splash-screen"));
const OnBoard = lazy(() => import("../views/on-board"));
const PrivateRoute = lazy(() => import("./private-route"));
const PermissionRoute = lazy(() => import("./permission-route"));
const Login = lazy(() => import("../views/login"));
const Locations = lazy(() => import("../views/locations"));

const REVIEWS = "reviews";
const CAMPAIGNS = "campaigns";
const MESSAGING = "messaging";
const TICKETING = "ticketing";

const routes = [
  {
      exact: true,
      name: REVIEWS,
      path: "/review-management",
      component: lazy(() => import("../views/reputation-dashboard")),
  },
  {
      exact: true,
      name: REVIEWS,
      path: "/review-management/my-reviews",
      component: lazy(() => import("../views/my-reviews")),
      meta: {
          permission: "reviews.my_reviews",
      },
  },
  {
    exact: true,
    name: REVIEWS,
    path: "/unpublished-review",
    component: lazy(() => import("../views/unpublished-review")),
    meta: {
        permission: "reviews.my_reviews",
    },
  },
  {
      exact: true,
      name: CAMPAIGNS,
      path: "/campaigns/customer-contacts",
      component: lazy(() => import("../views/customer-contacts/index")),
      meta: {
          permission: "campaigns.customer_contacts",
      },
  },
  {
      exact: true,
      name: MESSAGING,
      path: "/messaging",
      component: lazy(() => import("../views/messaging")),
      meta: {
          permission: "messaging.inbox.whatsapp_message",
      },
  },
  {
    exact: true,
    name: MESSAGING,
    path: "/messaging/assigned-to-me",
    component: lazy(() => import("../views/messaging"))
  },
  {
    exact: true,
    name: TICKETING,
    path: "/ticketing",
    component: lazy(()=> import("../views/camplaints-management/dashboard")),
  },
  {
    exact: true,
    name: TICKETING,
    path: "/ticketing/my-tickets",
    component: lazy(()=> import("../views/camplaints-management/my-tickets")),
    meta: {
        permission: "ticketing.my_tickets",
    },
  },
  {
    exact: true,
    name: TICKETING,
    path: "/ticketing/my-tickets/:uid",
    component: lazy(()=> import("../views/camplaints-management/my-tickets")),
    meta: {
        permission: "ticketing.my_tickets",
    },
  },
]

function Routes() {
  return (
    <>
      <Switch>
        <Route exact path="/" element={<SplashScreen />} />
        <Route exact path="on-board" element={<OnBoard />} />
        <Route exact path="login" element={<Login />} />
        <Route exact path="locations" element={<Locations />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<AppLayout />}>

            {routes.map((route, index) => (
                <Route 
                  key={index}
                  exact={route.exact} 
                  path={route.path} 
                  element={<PermissionRoute routeData={route} permission={route?.meta?.permission} element={<route.component />} />}
                />
            ))}

            {/* UI Ready */}
            {/* <Route exact path="post" element={<Post />} /> */}
            {/* <Route exact path="comments" element={<Comments />} /> */}
            {/* <Route exact path="new-post" element={<NewPost />} /> */}
            {/* <Route exact path="post-filter" element={<PostFilter />} /> */}
            {/* <Route exact path="review-analysis" element={<ReviewAnalysis />} /> */}
            {/* <Route exact path="individual-chat" element={<IndividualChat />} /> */}
            {/* <Route exact path="coustomize-each" element={<CoustomizeEach />} /> */}
            {/* <Route exact path="add-contacts" element={<AddContacts />} /> */}
            {/* <Route exact path="edit-contacts" element={<EditContacts />} /> */}
            {/* <Route exact path="publish-setting" element={<PublicSetting />} /> */}
            {/* <Route exact path="date-time-picker" element={<DateTimePicker />} /> */}
          </Route>
        </Route>
      </Switch>
    </>
  );
}

export default Routes;
