import Http from "../Http";
import * as action from "../store/actions";
import { SELECTED_LOCATION } from "../utills/const";
import eventBus from "../events/EventBus";

export function appData(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("app-data", { params })
        .then((res) => {
          eventBus.dispatch(SELECTED_LOCATION, res.data.data.businesses);
          dispatch(action.appData(res.data));
          return resolve();
        })
        .catch((err) => {
          if (!err.response) return;
          const { status, errors } = err.response.data;
          const data = {
            status,
            errors,
          };
          return reject(data);
        });
    });
}

export default appData;
