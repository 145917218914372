import * as ActionTypes from "../action-types";

const initialState = {
  data: [],
};

const appData = (state, payload) => {
  const stateObj = {
    ...state,
    isAuthenticated: true,
    data: payload,
  };
  window.rannkly = payload;
  return stateObj;
};

const AppData = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.ACCOUNT_DATA:
      return appData(state, payload);
    default:
      return state;
  }
};

export default AppData;
