import axios from "axios";
import Auth from "./store/reducers/Auth";
import { getUser } from "./utills";

const token = localStorage.getItem("access_token");
axios.defaults.headers.common.Authorization = `Bearer ${token}`;
axios.defaults.baseURL = process.env.REACT_APP_API_URL+ '/api/';

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 403 && !config?.sent) {
      config.sent = true;

      const accessToken = await refreshToken();

      if (accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        };
      }

      return axios(config);
    }
    if (
      !error?.config.url.includes("/login") &&
      error?.response?.status === 401
    ) {
      Auth(
        { isAuthenticated: false, user: null },
        {
          type: "AUTH_LOGOUT",
        }
      );
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

async function refreshToken() {
  const res = await axios.post(
    process.env.REACT_APP_API_URL + "/api/mobile/refresh",
    {
      device_id: getUser().email,
    }
  );
  if (res.data.success) {
    let AuthUser = res.data;

    Auth(
      { isAuthenticated: true, user: AuthUser },
      {
        type: "AUTH_LOGIN",
        payload: {
          access_token: AuthUser.data.access_token,
          user: AuthUser,
        },
      }
    );
    return AuthUser.data.access_token;
  }
}
export default axios;
