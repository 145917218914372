String.prototype.ucfirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.ucwords = function () {
    return this.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
    });
};

String.prototype.titleize = function () {
    return this.replace(/[-_]/g, " ")
        .split(" ")
        .map(str => str.ucfirst())
        .join(" ");
};

String.prototype.camleCase = function () {
    return this.replace(/[-_]/g, " ")
        .split(" ")
        .map((str, key) => (key == 0 ? str.toLowerCase() : str.ucfirst()))
        .join("");
};

String.prototype.kebabCase = function () {
    return this.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

String.prototype.slugCase = function (delimeter = '-') {
    return this.split(' ').join(delimeter).toLowerCase();
};



String.prototype.shuffle = function () {
    return this.split("").sort(() => 0.5-Math.random()).join("");
};

Array.prototype.countDuplicates = function (value) {
    let count = 0;
    for (let i = 0; i < this.length; i++) {
        if (this[i] == value) {
            count++;
        }
    }
    return count;
};
String.prototype.in = function (arr) {
    return arr.indexOf(this.toString()) > -1;
};

String.prototype.has = function (match) {
    return this.toString().includes(match);
};

String.prototype.truncate = function (length = 30, ending = "...") {
    if (this.length <= length) return this;

    let sliced = this.slice(0, length);

    return sliced.slice(0, sliced.lastIndexOf(" ")) + ending;
};

String.prototype.toObject = function () {
    if (this.indexOf('?') > -1) {
        return JSON.parse('{"' + decodeURI(this.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    }

    return this;
};

Array.prototype.pluck = function (name) {
    return this.map(val => val[name]);
};

Array.prototype.last = function () {
    return this[this.length - 1];
};

Array.prototype.first = function () {
    return this[0];
};

Array.prototype.sum = function (key) {
    return this.reduce((sum, item) => {
        return sum + (key ? item[key] : item);
    }, 0);
};

Array.prototype.has = function (element) {
    return this.indexOf(element) > -1;
};


Array.prototype.when = function (value, callback) {
    return value ? callback(this) : this;
};

Array.prototype.reject = function (callback) {
    return this.filter(el => !callback(el));
};

Array.prototype.max = function () {
    return Math.max(...this);
};
