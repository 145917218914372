import axios from "axios";
import { getUser } from "./index";
import Auth from "../store/reducers/Auth";
import { store } from "../store";
import eventBus from "../events/EventBus";
import { TOAST } from "../utills/const";

export const PWA_MENUS = [
    {
        menu_slug: '/review-management',
        sub_menus_slugs: ['/', '/my-reviews']
    },
    {
        menu_slug: '/campaigns',
        sub_menus_slugs: ['/customer-contacts']
    },
    {
        menu_slug: '/messaging',
        sub_menus_slugs: ['/', '/assigned-to-me']
    },
    {
        menu_slug: '/ticketing',
        sub_menus_slugs: ['/', '/my-tickets']
    }
];
export function filterMenus(navigations) {
    if (!navigations.length) return [];
    return navigations.map(menu => {
        const existMenuObj = PWA_MENUS.find(item => item.menu_slug === menu.slug);
        if (existMenuObj) {
            const filteredSubItems = menu.sub_items.filter(subItem => existMenuObj.sub_menus_slugs.includes(subItem.slug));
            return {
                ...menu,
                sub_items: filteredSubItems
            };
        }
    }).filter(item => { return item != null && item.sub_items.length > 0; });
}

export function navigateToFirstNav(navigations) {
    if (navigations) {
        const navigation = navigations.find((navi) => navi.enabled);
        const subNav = navigation.sub_items.find((subNavi) => subNavi.enabled)
        return subNav.slug == '/' ? navigation.slug : navigation.slug + subNav.slug;
    }
    return false;
}

export function userLogout() {
    const inputs = { device_id: getUser().email };
    axios
        .post(process.env.REACT_APP_API_URL + "/api/mobile/logout", inputs)
        .then((res) => {
            if (res.data.success) {
                Auth(
                    { isAuthenticated: false, user: null },
                    {
                        type: "AUTH_LOGOUT",
                    }
                );
            }
        });
}

export function canPerformAction(notation) {
    if (window?.rannkly?.user?.is_team_owner) return true
    const {UserNavigations} = store.getState()
    return Boolean(UserNavigations.permissions[notation])
}

export function handlePermissionDenied(error) {
    if (error?.response?.status === 400) {
        if (error.response?.data?.message) {
          eventBus.dispatch(TOAST, {
            type: "error",
            message: error.response.data.message,
          });
        }
    }
}