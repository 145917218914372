//@ts-nocheck
import React, { useEffect, useState } from "react";
import eventBus from "../../events/EventBus";
import { TOAST } from "../../utills/const";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";

function Toast() {
  const [visible, setVisible] = useState(false);
  const [config, setConfig] = useState({});
  const vertical = "top";
  const horizontal = "center";

  useEffect(() => {
    eventBus.on(TOAST, handleToast);
  }, []);

  function handleToast(toastConfig = {}) {
    setConfig({ ...toastConfig });
    if (!visible) {
      setTimeout(closeToast, (toastConfig.time || 5) * 1000);
    }
    setVisible(!visible);
  }

  function closeToast() {
    setConfig({});
    setVisible(false);
  }

  if (!visible) return null;

  return (
    <Snackbar
      open={visible}
      autoHideDuration={6000}
      anchorOrigin={{ vertical, horizontal }}
      TransitionComponent={Slide}
    >
      <Alert onClose={closeToast} severity={config.type} sx={{ width: "100%" }}>
        {config.message}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
