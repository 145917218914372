import Http from "../Http";
import * as action from "../store/actions";

export function widgetsColor(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("app-data", { params })
        .then((res) => {
          dispatch(action.widgetsColor(res.data));
          return resolve();
        })
        .catch((err) => {
          if (!err.response) return;
          const { status, errors } = err.response.data;
          const data = { status, errors};
          return reject(data);
        });
    });
}

export default widgetsColor;
