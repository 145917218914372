import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Badge from "@mui/material/Badge";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Toolbar from "@mui/material/Toolbar";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Auth from "../../store/reducers/Auth";
import IconButton from "@mui/material/IconButton";
import styles from "./style.module.scss";
import Divider from "@mui/material/Divider";
import { filterMenus } from "../../utills/user-permission";

import Icon from "@mdi/react";
import eventBus from "../../events/EventBus";
import { styled } from "@mui/material/styles";
import { getUser } from "../../utills";
import { mdiMenu, mdiMapMarker, mdiStarBox, mdiShape, mdiAccountBox, mdiTicketAccount, mdiChat, mdiViewDashboard, mdiLogout, mdiChevronRight, mdiAccountCircle } from "@mdi/js";
import { Link, useLocation, NavLink } from "react-router-dom";
import { SELECTED_LOCATION } from "../../utills/const";

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<Icon path={mdiChevronRight} size={1} color="#151515" />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse", "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

function LeftMenu(props) {
  const [user, setUser] = useState();
  const [state, setState] = useState(false);
  const [locationCount, setLocationCount] = useState("All");
  const [currentUrlSlug, setCurrentUrlSlug] = useState('');

  const [navigations, setNavigations] = useState([]);

  const MENU_ICONS = {
    "view-dashboard": mdiShape,
    "star-box": mdiStarBox,
    "account-box": mdiAccountBox,
    "message-text" : mdiChat,
    "account-circle" : mdiAccountCircle,
    "view-dashboard" :mdiViewDashboard,
    "ticket-account" :mdiTicketAccount,
  };

  useEffect(() => {
    setUser(getUser());
    getNavigations();
  }, []);

  const location = useLocation();

  useEffect(() => {
    setState(false);
    setCurrentUrlSlug(location.pathname.split('/').filter(segment => segment !== '').join('-'));
  }, [location.pathname]);

  useEffect(() => {
    eventBus.on(SELECTED_LOCATION, countSelectedLocations);
  });

  function countSelectedLocations(items) {
    const data = JSON.parse(JSON.stringify(items)).filter((b) => {
      return b.enabled;
    });
    let lCount = items.length == data.length ? "All" : data.length;
    setLocationCount(lCount);
  }

  function logout() {
    const inputs = { device_id: getUser().email };
    axios
      .post(process.env.REACT_APP_API_URL + "/api/mobile/logout", inputs)
      .then((res) => {
        if (res.data.success) {
          Auth(
            { isAuthenticated: false, user: null },
            {
              type: "AUTH_LOGOUT",
            }
          );
          window.location.href = "/login";
        } else {
          //setError(res.data.message);
        }
      });
  }
  function toggleMenu() {
    setState((open) => !open);
  }

  function getNavigations() {
    axios.get("app-data?action=get_navigation_menu").then((res) => {
      setNavigations(filterMenus(res.data.navigations));
    });
  }

  function convertToTitleCase(str) {
    let words = str.split('_');
    let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    let result = capitalizedWords.join(' ');
    return result;
  }

  function getUrlSlug(endPoint) {
    return endPoint.split('/').filter(segment => segment !== '').join('-');
  }
  return (
    <>
      <div className={styles.TopSection}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton onClick={toggleMenu} edge="start" aria-label="menu" sx={{ mr: 3 }} color="black100">
              <Icon path={mdiMenu} size={1} />
            </IconButton>
            <Typography variant="body1" className="capitalize" sx={{ flexGrow: 1 }} >
              {window.location.href.split("/").pop().replace("-", " ")}
            </Typography>
            <Link to="/locations">
              <IconButton color={locationCount == "All" ? "black60" : "orangeMain"}>
                <Icon path={mdiMapMarker} size={1} />
                <Typography variant="h6" sx={{ color: "inherit" }}>
                  {locationCount}
                </Typography>
              </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Divider />
      </div>

      <Drawer anchor={"left"} open={state} onClose={toggleMenu}>
        <List>
          <Stack direction="row" alignItems="center" gap="8px" p={2}>
            <Badge overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }} >
              <Avatar className="avtar60" alt="user" src={user?.avatar} />
            </Badge>
            <Stack direction="column">
              <Typography variant="body1"> {user?.full_name}</Typography>
              <Typography variant="subtitle1"> {user?.email}</Typography>
            </Stack>
          </Stack>
          <Box>
            {!!navigations.length &&
              navigations.map((navigation, index) => {
                return (
                <Accordion 
                  key={"navigation-"+index}
                  className="borderBottom" 
                  defaultExpanded={currentUrlSlug.includes(getUrlSlug(navigation.slug)) ? true : false}
                  key={index}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    className="hamburgerAccordion "
                  >
                    <Typography variant="body1" ml={2}>
                      {navigation.display_name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container direction="column" className="hamburgerMenu">
                      {!!navigation.sub_items.length && 
                        navigation.sub_items.map((sub_menu, i) => {
                          return (
                          <Link 
                            key={"link-"+i}
                            to={sub_menu.slug == '/' ? navigation.slug : navigation.slug+sub_menu.slug} 
                            className={currentUrlSlug === getUrlSlug(navigation.slug+sub_menu.slug) ? "active" : ""}
                            key={i}
                          >
                            <Grid container direction="row" alignItems="center" gap="10px" >
                              <Icon path={MENU_ICONS[sub_menu.icon]} size={1} />
                              <Typography variant="h6">{sub_menu.display_name || convertToTitleCase(sub_menu.name)}</Typography>
                            </Grid>
                          </Link>
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}

            <Grid container direction="column" className="hamburgerMenu">
              <Grid container direction="row" alignItems="center" gap="10px">
                <IconButton color="primaryMain">
                  <Icon path={mdiLogout} size={1} />
                </IconButton>
                <Typography variant="body1" onClick={logout}>
                  Logout
                </Typography>
              </Grid>
            </Grid>

          </Box>
        </List>
      </Drawer>
    </>
  );
}

export default LeftMenu;
