import { Outlet } from "react-router-dom";
import LeftMenu from "../../components/sidebar";

function AppLayout() {
  return (
    <>
      <LeftMenu />
      <Outlet />
    </>
  );
}

export default AppLayout;
